import React, { useState, useRef } from 'react';
import Wrapper from '@components/wrapper.styled';
import Section from '@components/section';
import Text, { TextHead } from '@components/text';
import BioCard, { BioCardProps } from '@components/bio-card';
import generateId from '@helpers/generate-id';
import { StyledGrid } from './team.styled';

const teamIdGenerator = generateId();

interface TeamItemProps {
  bio?: {
    document?: {
      data?: BioCardProps;
    };
  };
}

interface TeamProps {
  eyebrow?: string;
  title?: string;
  items?: TeamItemProps[];
}

const Team = ({ eyebrow, title, items = [] }: TeamProps) => {
  const [openedCardIndex, setOpenedCardIndex] = useState<number | null>(null);
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const handleClick = (index: number) => {
    setOpenedCardIndex(openedCardIndex !== index ? index : null);
    if (anchorRef.current !== null) {
      anchorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Section backgroundColor="white">
      <Wrapper>
        <TextHead ref={anchorRef}>
          <Text type="eyebrow" stringText={eyebrow} />
          <Text type="title" stringText={title} textSize="heading5" />
        </TextHead>
        <StyledGrid>
          {items?.map(({ bio }, i) => {
            const { photo, name, job_title, description, social_links } = bio?.document?.data || {};
            return (
              <BioCard
                key={teamIdGenerator.next().value}
                action={() => handleClick(i)}
                isOpen={openedCardIndex === i}
                photo={photo}
                name={name}
                job_title={job_title}
                description={description}
                social_links={social_links || []}
              />
            );
          })}
        </StyledGrid>
      </Wrapper>
    </Section>
  );
};

export default Team;
