import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import CallToAction from '@components/call-to-action';
import PageHero from '@components/page-hero';
import HeroWithSubarticles from '@components/hero-with-subarticles';
import Image, { ImageProps } from '@components/image';
import Resources from '@components/resources';
import Showcase from '@components/showcase';
import ProductOverview from '@components/product-overview';
import GreenhouseJobBoard from '@components/greenhouse-job-board';
import PostsListContainer from '@components/posts-list-container';
import Carousel from '@components/carousel';
import Investors from '@components/investors';
import FollowTheLink from '@components/follow-the-link';
import BoardMembers from '@components/board-members';
import FeatureGrid from '@components/feature-grid';
import RichText from '@components/rich-text';
import Team from '@components/team';
import HowItWorks from '@components/how-it-works';
import { defaultTheme } from '@styles/theme.styled';
import CodeCarousel from '@components/code-carousel';
import { Item } from './items-props.type';
import { ModuleContainer, ImageContainer } from './module.shared.styled';

export interface PrimaryProps {
  eyebrow?: {
    richText: RichTextBlock[];
    text: string;
  };
  title?: {
    richText: RichTextBlock[];
    text: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  subtitle?: {
    richText: RichTextBlock[];
    text: string;
  };
  subheading?: {
    richText: RichTextBlock[];
    text: string;
  };
  link: {
    url: string;
    target: string;
  };
  link_label: string;
  prompt?: string;
  image: ImageProps;
  left_image: ImageProps;
  right_image: ImageProps;
  sign_up_form?: boolean;
  post_title?: {
    text: string;
  };
  input_placeholder?: string;
  button_label: string;
  button_link: {
    url: string;
    target: string;
  };
  position?: string;
  full_page: boolean;
  align?: string;
  left_image_position?: string;
  right_image_position?: string;
  token: string;
  items_per_row?: string;
  color?: keyof typeof defaultTheme.colors;
  background_color?: keyof typeof defaultTheme.colors;
  background_image?: ImageProps;
  background_position?: string;
  rich_text?: {
    richText: RichTextBlock[];
  };
  image_layout: 'inline' | 'masked';
}

export interface ModuleProps {
  slice_type: string;
  slice_label: string;
  primary: PrimaryProps;
  items: Item[];
}

const renderModule = ({
  slice_type,
  slice_label,
  primary,
  items,
}: ModuleProps): JSX.Element | null => {
  const itemsPerRow = (slice_label && +slice_label.slice(0, 1)) || 3;
  switch (slice_type) {
    case 'page_hero':
      return (
        <PageHero
          title={primary.title?.text}
          description={primary.description?.richText}
          subheading={primary.subheading?.richText}
          image={primary.image}
          imageLayout={primary.image_layout}
          backgroundColor={primary.background_color}
        />
      );
    case 'hero_with_subarticles':
      return (
        <HeroWithSubarticles
          moduleTitle={primary.title?.text}
          subheading={primary.subheading?.richText}
          items={items}
        />
      );
    case 'feature_grid':
      return (
        <FeatureGrid
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          subheading={primary.subheading?.richText}
          link={primary.link}
          linkLabel={primary.link_label}
          backgroundColor={primary.background_color}
          backgroundImage={primary.background_image}
          backgroundPosition={primary.background_position}
          itemsPerRow={primary.items_per_row}
          items={items}
        />
      );
    case 'how_it_works':
      return (
        <HowItWorks
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          richText={primary.rich_text?.richText}
          image={primary.image}
        />
      );
    case 'product_overview':
      return (
        <ProductOverview
          label={slice_label}
          title={primary.title?.text}
          description={primary.description?.richText}
          items={items}
        />
      );
    case 'showcase':
      return (
        <Showcase
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          description={primary.description?.richText}
          linkUrl={primary.link.url}
          linkLabel={primary.link_label}
        />
      );
    case 'call_to_action':
      return (
        <CallToAction
          title={primary.title?.text}
          link={primary.link}
          linkLabel={primary.link_label}
          backgroundColor={primary.background_color}
          backgroundImage={primary.background_image}
        />
      );
    case 'image': {
      if (!primary.image?.gatsbyImageData) {
        return <></>;
      }

      return (
        <ModuleContainer>
          <ImageContainer>
            <Image gatsbyImageData={primary.image?.gatsbyImageData} url={primary.image?.url} />
          </ImageContainer>
        </ModuleContainer>
      );
    }
    case 'community':
      return (
        <Resources
          moduleTitle={primary.title?.text}
          description={primary.description?.richText}
          items={items}
        />
      );
    case 'posts_list':
      return (
        <ModuleContainer>
          <PostsListContainer primary={primary} />
        </ModuleContainer>
      );
    case 'carousel':
      return <Carousel items={items} />;
    case 'investors':
      return (
        <Investors eyebrow={primary.eyebrow?.text} title={primary.title?.text} items={items} />
      );
    case 'angel_investors':
      return (
        <BoardMembers
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          items={items}
          itemsPerRow={itemsPerRow}
        />
      );
    case 'follow_the_link':
      return (
        <FollowTheLink
          moduleTitle={primary.title?.text}
          description={primary.description?.richText}
          buttonLabel={primary.button_label}
          buttonUrl={primary.button_link?.url}
          image={primary.image}
        />
      );
    case 'code_demo_carousel':
      return (
        <CodeCarousel
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          prompt={primary.prompt}
          items={items}
        />
      );
    case 'greenhouse_job_board':
      return (
        <GreenhouseJobBoard
          eyebrow={primary.eyebrow?.text}
          title={primary.title?.text}
          token={primary.token}
          full_page={primary.full_page}
          backgroundColor={primary.background_color}
        />
      );
    case 'team':
      return <Team eyebrow={primary.eyebrow?.text} title={primary.title?.text} items={items} />;
    case 'rich_text':
      return <RichText title={primary.title?.text} description={primary.description?.richText} />;
    default:
      return null;
  }
};

export default renderModule;
