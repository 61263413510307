import React from 'react';
import {
  ChromeFrame,
  ChromeControls,
  CloseControl,
  MinimizeControl,
  MaximizeControl,
  ChromeBody,
  LinesWrapper,
  Line,
  Code,
} from './terminal.styled';

interface TerminalProps {
  children?: JSX.Element | JSX.Element[];
}

const Terminal = ({ children }: TerminalProps): JSX.Element => {
  const Lines: JSX.Element[] = [];

  for (let i = 0; i < 10; i += 1) {
    Lines.push(<Line key={`line-${i}`}>{i + 1}</Line>);
  }

  return (
    <ChromeFrame>
      <ChromeControls>
        <CloseControl />
        <MinimizeControl />
        <MaximizeControl />
      </ChromeControls>
      <ChromeBody>
        <LinesWrapper>{Lines}</LinesWrapper>
        <Code>{children}</Code>
      </ChromeBody>
    </ChromeFrame>
  );
};

export default Terminal;
