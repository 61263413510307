import React, { useRef, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { RichTextBlock } from 'prismic-reactjs';
import Text, { TextHead } from '@components/text';
import Wrapper from '@components/wrapper.styled';
import Slider from 'react-slick';
import Section from '@components/section';
import generateId from '@helpers/generate-id';
import { defaultTheme } from '@styles/theme.styled';
import Terminal from './terminal';
import {
  SlickContainer,
  SliderNav,
  SliderNavList,
  StyledInput,
  Prompt,
  Indicator,
  SliderNavItem,
  SlideLabel,
  SlideSelect,
  PropertyContainer,
  CodeSection,
  CodeWrapper,
  UppercaseCode,
  AccentCode,
  DefaultCode,
} from './code-carousel.styled';

const carouselIdGenerator = generateId();

export interface CodeCarouselItemProps {
  slide_caption?: string;
  code: {
    richText: RichTextBlock[];
  };
}

interface CodeCarouselProps {
  eyebrow?: string;
  title?: string;
  prompt?: string;
  items?: CodeCarouselItemProps[];
}

const CodeCarousel = ({ eyebrow, title, prompt, items = [] }: CodeCarouselProps): JSX.Element => {
  const sliderRef = useRef<Slider | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [userProp, setUserProp] = useState<string | null>('Concept');
  const [autoplayState, setAutoplayState] = useState<boolean>(false);
  const [dimensions, setDimensions] = useState({
    windowHeight: 0,
    windowWidth: 0,
  });

  // save dynamic variable text
  const saveInput = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setUserProp(newValue);
  };

  const slideTo = (index: number) => {
    const sliderElement = sliderRef.current;
    if (sliderElement) {
      sliderElement.slickGoTo(index);
    }
  };

  // play & set autoplay state to true if on desktop resolutions
  const handleAutoplay = () => {
    const sliderElement = sliderRef.current;
    const isDesktopWidth = dimensions.windowWidth >= parseInt(defaultTheme.breakpoints.md, 10);
    if (isDesktopWidth && sliderElement) {
      sliderElement.slickPlay();
      setAutoplayState(true);
    }
  };

  // pause and set autoplay state to false
  const pauseSlider = () => {
    const sliderElement = sliderRef.current;

    if (sliderElement) {
      sliderElement.slickPause();
      setAutoplayState(false);
    }
  };

  const settings = {
    dots: false,
    arrows: false,
    autoplay: false,
    pauseOnHover: false,
    pauseOnFocus: false,
    autoplaySpeed: 12000,
    infinite: true,
    speed: 250,
    swipe: false,
    swipeToSlide: false,
    beforeChange: (_: number, index: number) => {
      if (inputRef.current !== null) {
        inputRef.current.value = '';
      }
      setUserProp('Concept');
      setSlideIndex(index);
    },
    responsive: [
      {
        breakpoint: parseInt(defaultTheme.breakpoints.md, 10),
        settings: {
          swipe: true,
          swipeToSlide: true,
        },
      },
    ],
  };

  // responsive handler for autoplay state
  useEffect(() => {
    // set window dimensions & autoplay state
    const handler = () => {
      setDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      });
      handleAutoplay();
    };

    // initiate handle and attach to resize event if on client / window available
    if (typeof window !== `undefined`) {
      handler();
      window.addEventListener(`resize`, handler);
    }

    // remove listener
    return () => window.removeEventListener(`resize`, handler);
  }, []);

  // handle default state for dynamic variable
  useEffect(() => {
    if (userProp === '') {
      setUserProp('Concept');
    }
  }, [userProp]);

  return items?.length > 0 ? (
    <Section backgroundColor="white">
      <Wrapper>
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            charSet="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
        </Helmet>
        <TextHead align="left">
          {!!eyebrow && <Text type="eyebrow" stringText={eyebrow} />}
          {!!title && <Text type="title" stringText={title} />}
        </TextHead>
        <CodeWrapper onMouseEnter={pauseSlider} onMouseLeave={handleAutoplay}>
          <SliderNav>
            <SliderNavList>
              {items.map(({ slide_caption }, index) => {
                return (
                  <SliderNavItem key={carouselIdGenerator.next().value}>
                    <SlideSelect
                      $isAutoPlaying={autoplayState}
                      $isActive={slideIndex === index}
                      role="button"
                      onClick={() => slideTo(index)}
                    >
                      <Indicator />
                      <SlideLabel>{slide_caption}</SlideLabel>
                    </SlideSelect>
                  </SliderNavItem>
                );
              })}
            </SliderNavList>
          </SliderNav>
          <CodeSection>
            <PropertyContainer>
              <Prompt>{prompt}</Prompt>
              <StyledInput
                type="text"
                placeholder="Concept"
                onChange={saveInput}
                onFocus={pauseSlider}
                onBlur={handleAutoplay}
                ref={inputRef}
              />
            </PropertyContainer>
            <Slider ref={sliderRef} {...settings}>
              {items?.map(({ code }) => {
                return (
                  <SlickContainer key={carouselIdGenerator.next().value}>
                    <Terminal>
                      {code.richText.map((line) => {
                        const text: string = line.text || '';
                        const words = text.split(/\s+/g);
                        const build = words.map((word) => {
                          const isUppercase = /^[A-Z]*$/.test(word);
                          if (isUppercase) {
                            return (
                              <UppercaseCode key={carouselIdGenerator.next().value}>
                                {word}{' '}
                              </UppercaseCode>
                            );
                          }
                          return word.match(/your_value/) ? (
                            <AccentCode key={carouselIdGenerator.next().value}>
                              {userProp}{' '}
                            </AccentCode>
                          ) : (
                            <DefaultCode key={carouselIdGenerator.next().value}>
                              {`${word} `}
                            </DefaultCode>
                          );
                        });
                        return <div key={carouselIdGenerator.next().value}>{build}</div>;
                      })}
                    </Terminal>
                  </SlickContainer>
                );
              })}
            </Slider>
          </CodeSection>
        </CodeWrapper>
      </Wrapper>
    </Section>
  ) : (
    <></>
  );
};

export default CodeCarousel;
