import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import generateId from '@helpers/generate-id';
import Wrapper from '@components/wrapper.styled';
import Section from '@components/section';
import Text from '@components/text';
import {
  Container,
  Title,
  BlockContainer,
  Block,
  BlockTitle,
  BlockDescription,
} from './hero-with-subarticles.styled';

const heroWithSubarticlesIdGenerator = generateId();

export interface HeroWithSubarticlesItemProps {
  title?: {
    text?: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
}

export interface HeroWithSubarticlesProps {
  moduleTitle?: string;
  subheading?: RichTextBlock[];
  items?: HeroWithSubarticlesItemProps[];
}

const HeroWithSubarticles = ({
  moduleTitle,
  subheading = [],
  items = [],
}: HeroWithSubarticlesProps): JSX.Element => {
  const hasSubheading: boolean = subheading?.length > 0 && subheading[0].text !== '';

  if (!moduleTitle) {
    return <></>;
  }

  return (
    <Section backgroundColor="white">
      <Wrapper widthSize="md" alignment="center">
        <Container>
          <Title tag="h2" textSize="heading2" type="title" stringText={moduleTitle} />
          {hasSubheading && <Text type="subheading" richText={subheading} />}
          <BlockContainer>
            {items?.map(({ title, description }) => {
              const hasDescription: boolean =
                description?.richText.length !== undefined &&
                description?.richText.length > 0 &&
                description?.richText[0].text !== '';

              if (title?.text === '') {
                return null;
              }

              return (
                <Block key={heroWithSubarticlesIdGenerator.next().value}>
                  <BlockTitle tag="h3" textSize="heading6" type="title" stringText={title?.text} />
                  {hasDescription && (
                    <BlockDescription type="description" richText={description?.richText} />
                  )}
                </Block>
              );
            })}
          </BlockContainer>
        </Container>
      </Wrapper>
    </Section>
  );
};

export default HeroWithSubarticles;
