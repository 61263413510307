import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import Text from '@components/text';
import Wrapper from '@components/wrapper.styled';
import { StyledArrow } from '@components/button/button.styled';
import { defaultTheme } from '@styles/theme.styled';
import ExternalLink from '@components/external-link/external-link.styled';
import {
  StyledSection,
  Content,
  ColumnWrapper,
  TerminalContent,
  InstructionWrapper,
} from './showcase.styled';
import Terminal from './terminal';
import CommandLine from './command-line';

interface ShowcaseProps {
  backgroundColor?: keyof typeof defaultTheme.colors;
  eyebrow?: string;
  title?: string;
  description?: RichTextBlock[];
  linkUrl?: string;
  linkLabel?: string;
}

const Showcase = ({
  backgroundColor = 'primaryLight',
  eyebrow = '',
  title = '',
  description = [],
  linkUrl,
  linkLabel,
}: ShowcaseProps): JSX.Element => {
  const hasDescription: boolean = description?.length > 0 && description[0].text !== '';

  if (!title || !hasDescription) {
    return <></>;
  }

  return (
    <StyledSection backgroundColor={backgroundColor} isDiagonal className="section-showcase">
      <Wrapper>
        <ColumnWrapper>
          <Content>
            {eyebrow && <Text type="eyebrow" textColor="dark" stringText={eyebrow} />}
            <Text textSize="heading4" tag="h2" textColor="dark" type="title" stringText={title} />
            <Text textColor="dark" richText={description} />
            {linkUrl && linkLabel && (
              <ExternalLink variant="white" href={linkUrl} rel="noreferrer" target="_blank">
                {linkLabel}
                <StyledArrow />
              </ExternalLink>
            )}
          </Content>
          <TerminalContent>
            <Terminal />
            <InstructionWrapper>
              <Text type="eyebrow" textColor="tertiary" stringText="Get Started" />
            </InstructionWrapper>
            <CommandLine />
          </TerminalContent>
        </ColumnWrapper>
      </Wrapper>
    </StyledSection>
  );
};

export default Showcase;
