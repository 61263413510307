import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import Grid from '@components/grid';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';

export const GridBlock = styled.div`
  flex: 1;
  width: 100%;
`;

export const FlexContainer = styled.div<{ layout?: 'row' | 'column' }>`
  ${({ theme, layout = 'column' }) => css`
    display: flex;
    flex-direction: ${layout};
    gap: 4rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      align-items: center;
      justify-content: center;
      row-gap: 2rem;
    }
  `}
`;

export const StyledBlock = styled.div<{ textColor?: keyof typeof defaultTheme.colors }>`
  ${({ theme, textColor = 'bottleGreen' }) => css`
    ${generateScales('padding-top', '2rem', '2.5rem')}
    color: ${theme.colors[textColor]};
    border-top: 1px solid
      ${textColor === 'white' ? theme.colors[textColor] : theme.colors.jungleGreen};

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      max-width: unset;
    }
  `}
`;

export const LeftStyledBlock = styled(StyledBlock)`
  ${({ theme }) => css`
    width: 24rem;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      width: 20rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: 100%;
    }
  `}
`;

export const StyledDescription = styled(Text)`
  font-weight: 500;
`;

export const StyledExternalLink = styled.a`
  display: flex;
  border: none;
  padding: 0;
  gap: 1.5rem;
  text-decoration: underline;
  align-items: center;

  ${({ theme }) => css`
    color: ${theme.colors.bottleGreen};
    ${generateScales(
      'font-size',
      theme.fontSize.subheading.mobile,
      theme.fontSize.subheading.desktop
    )}
    font-weight: ${theme.fontWeights.normal};
    font-family: ${theme.fontFamily.display};

    &:hover {
      color: ${theme.colors.jungleGreen};
      text-decoration: none;
    }
  `}
`;

export const StyledSubheading = styled(Text)`
  + ${StyledExternalLink} {
    ${generateScales('margin-top', '1rem', '1.5rem')}
  }
`;

export const StyledHeading = styled(Text)`
  + ${StyledSubheading} {
    ${generateScales('margin-top', '1rem', '1.5rem')}
    display: block;
  }
`;

export const StyledTitle = styled(Text)`
  font-weight: bold;
  margin: 0;

  + ${StyledDescription} {
    margin-top: 1.5rem;
    display: block;
  }
`;

export const StyledGrid = styled(Grid)`
  margin: 0;
  flex: 1 auto;
`;
