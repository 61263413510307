import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import Prismic from '@prismicio/client';
import { CategoriesBlog } from '@components/module/module.shared.styled';
import FeaturePost from '@components/feature-post';
import Text from '@components/text';
import PostsList, { PostPreviewType } from '@components/posts-list';
import { PrimaryProps } from '@components/module';
import Pagination, { PageInfoProps } from '@components/pagination';

const apiEndpoint = 'https://coactive.cdn.prismic.io/api/v2';
const PAGE_SIZE = 9;

export interface PostListContainerProps {
  primary: PrimaryProps;
}

const PostListContainer = ({ primary }: PostListContainerProps): JSX.Element => {
  const location = useLocation();
  const pageParam = new URLSearchParams(location.search).get('page') || '';
  const activeCategory = new URLSearchParams(location.search).get('category') || '';
  const activeSearch = new URLSearchParams(location.search).get('search') || '';

  const [posts, setPosts] = useState<PostPreviewType[]>([]);
  const [featuredPosts, setFeaturedPosts] = useState<PostPreviewType[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfoProps>({
    page: pageParam || 1,
    prev_page: null,
    next_page: null,
    total_pages: 1,
  });

  const client = Prismic.client(apiEndpoint);

  const loadPosts = (
    setValues: (values: PostPreviewType[]) => void,
    currentPage = 1,
    areFeaturedPosts = false
  ) => {
    const fetchData = async () => {
      const response = await client.query(
        [
          Prismic.Predicates.at('document.type', 'post'),
          Prismic.Predicates.fulltext('document', activeSearch),
          Prismic.Predicates.at('my.post.featured_post', areFeaturedPosts),
          ...(activeCategory ? [Prismic.Predicates.at('document.tags', [activeCategory])] : []),
        ],
        {
          orderings: '[my.post.first_publication_date]',
          fetch: ['my.post.post_title', 'my.post.post_description', 'my.post.post_image'],
          fetchLinks: ['my.post.post_image'],
          pageSize: PAGE_SIZE,
          page: currentPage,
        }
      );

      if (response) {
        const { page, prev_page, next_page, total_pages, results } = response;
        if (!areFeaturedPosts) {
          setPageInfo({
            page,
            prev_page,
            next_page,
            total_pages,
          });
        }
        setValues([...results] as PostPreviewType[]);
      }
    };

    fetchData()
      .then((r) => console.info(r))
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    loadPosts(setFeaturedPosts, 1, true);
    loadPosts(setPosts, +pageInfo.page, false);
  }, []);

  return (
    <div>
      <CategoriesBlog />
      <FeaturePost posts={featuredPosts} />
      {primary.post_title?.text && <Text type="title" stringText={primary.post_title?.text} />}
      <PostsList title={primary.title?.text} posts={posts} />
      <Pagination pageInfo={pageInfo} />
    </div>
  );
};

export default PostListContainer;
