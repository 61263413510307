import styled from 'styled-components';
import { Link } from 'gatsby';
import Arrow from '@components/svg-icons/arrow';
import { generateScales } from '@helpers/generate-media-queries';

export const DropdownContainer = styled.div`
  width: fit-content;
  position: relative;
`;

export const StyledArrow = styled(Arrow)`
  margin-left: 0.5rem;
  transform: rotate(90deg);
  height: auto;
  width: 1rem;
`;

const mainAnimationTime = 0.25;

export const StyledDropDown = styled.ul<{ isVisible?: boolean }>`
  ${({ theme, isVisible }) => `
    padding: 0.5rem 0.5rem;
    width: 100%;
    min-width: 8rem;
    max-width: 12rem;
    position: absolute;
    top: calc(100% - 2rem);
    left: 0;
    margin: 0;
    opacity: 0;
    font-family: ${theme.fontFamily.display};
    z-index: 5;
    ${generateScales('font-size', '0.8375rem', '1rem')}
    border: 1px solid ${theme.colors.bottleGreen};
    border-radius: ${theme.borderRadius.small};
    background-color: ${theme.colors.bottleGreen};
    transition: top ${mainAnimationTime}s, visibility 0.01s ${mainAnimationTime}s,
    opacity ${mainAnimationTime}s;
    pointer-events: none;
    box-shadow: ${theme.shadows.small};

    ${
      isVisible
        ? `
    pointer-events: auto;
    top: calc(100% + 0.5rem);
    transition: top ${mainAnimationTime}s 0.01s, visibility 0.01s, opacity ${mainAnimationTime}s 0.01s;
    visibility: visible;
    opacity: 1;
    `
        : ``
    };
  `}
`;

export const Button = styled.button<{ isVisible?: boolean }>`
  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.sm;

    return `
    text-decoration: none;
    font-family: ${theme.fontFamily.display};
    font-size: ${theme.fontSize.body.desktop};
    cursor: pointer;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    border-width: ${theme.borderWidths.ui};
    border-style: solid;
    border-color: ${theme.colors.mindaro};
    background-color: ${theme.colors.bottleGreen};
    color: ${theme.colors.mindaro};

    ${generateScales('font-size', '0.8375rem', '1rem')}
    ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius)}
    ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius)}
    ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius)}
    ${generateScales('height', mobile.height, desktop.height)}
    `;
  }}

  ${({ isVisible }) => `
    ${
      isVisible
        ? `
        ${StyledArrow} {
          transform: rotate(-90deg);
        }
    `
        : ``
    };
  `}
`;

export const Text = styled.span`
  ${({ theme }) => `
    width: 100%;
    color: ${theme.colors.mindaro};
    font-family: ${theme.fontFamily.display};
    ${generateScales('font-size', '0.8375rem', '1rem')}
    line-height: ${theme.lineHeights.sm};
    text-decoration: none;
    border: none;
  `}
`;

export const Links = styled(Link)`
  display: flex;
  padding: 0.5rem 0.5rem;
  text-decoration: none;

  ${({ theme }) => `
    border-radius: calc(${theme.borderRadius.small} / 2);

    :hover {
      ${Text} {
        color: ${theme.colors.white};
      }
    }
  `}
`;
