import React, { useState, useRef } from 'react';
import useOnClickOutside from '@hooks/use-on-click-outside';
import generateId from '@helpers/generate-id';
import {
  DropdownContainer,
  StyledDropDown,
  Button,
  Text,
  Links,
  StyledArrow,
} from './dropdown.styled';

export interface DropdownProps {
  items: string[];
  title: string;
  type: string;
}
const dropdownIdGenerator = generateId();

const Dropdown = ({ title, items, type }: DropdownProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <DropdownContainer ref={ref}>
      <Button onKeyPress={toggle} onClick={toggle} isVisible={isOpen}>
        {title} <StyledArrow color="mindaro" />
      </Button>
      <StyledDropDown isVisible={isOpen}>
        {items.map((tag) => {
          const to = `/${type}?category=${tag}`;

          return (
            <Links to={to} key={dropdownIdGenerator.next().value}>
              <Text>{tag}</Text>
            </Links>
          );
        })}
      </StyledDropDown>
    </DropdownContainer>
  );
};

export default Dropdown;
