export interface LinkType {
  url?: string;
  uid?: string;
}

const getUrl = (link: LinkType | undefined): string => {
  if (link) {
    return link.uid ? `/${link.uid}` : link.url || '';
  }
  return '';
};

export default getUrl;
