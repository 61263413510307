import styled from 'styled-components';
import CategoriesBlock from '@components/categories';
import Text from '@components/text';

export const ModuleContainer = styled.div`
  margin: 0;
  width: 100%;
`;

export const ImageContainer = styled.div`
  width: 100%;
  justify-content: stretch;
  align-items: stretch;
  border-radius: 1rem;
  overflow: hidden;

  div {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const CategoriesBlog = styled(CategoriesBlock)`
  margin-bottom: 0rem;
`;

export const CategoriesPost = styled(CategoriesBlock)`
  margin-top: 4rem;
`;

export const Title = styled(Text)`
  font-size: 2.75rem;
  line-height: 3rem;
`;
