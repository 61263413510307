import React from 'react';
import ContentBlock from '@components/content-block';
import { RichTextBlock } from 'prismic-reactjs';
import { ImageProps } from '@components/image';
import { ModuleContainer } from '@components/module/module.shared.styled';
import generateId from '@helpers/generate-id';
import { OverviewGrid, Title, Description } from './product-overview';

const productOverviewIdGenerator = generateId();

export interface OverviewItemProps {
  image?: ImageProps;
  title?: {
    text: string;
  };
}

interface ProductOverviewProps {
  title?: string;
  description?: RichTextBlock[];
  items?: OverviewItemProps[];
  label?: string;
}

const ProductOverview = ({
  title,
  description = [],
  items = [],
  label,
}: ProductOverviewProps): JSX.Element => {
  const itemsPerRow = label ? +label.slice(0, 2) : undefined;

  const hasDescription: boolean = description?.length > 0 && description[0].text !== '';
  const hasItems: boolean = items?.length > 0 && items[0].title?.text !== '';

  if (!title && !hasDescription && !hasItems) {
    return <></>;
  }

  return (
    <ModuleContainer>
      {title && <Title type="title" stringText={title} />}
      {hasDescription && <Description type="description" richText={description} />}
      {hasItems && (
        <OverviewGrid itemsPerRow={label ? +label.slice(0, 2) : undefined}>
          {items.map((item) => (
            <ContentBlock
              key={productOverviewIdGenerator.next().value}
              image={item.image}
              title={item.title}
              itemsPerRow={itemsPerRow}
            />
          ))}
        </OverviewGrid>
      )}
    </ModuleContainer>
  );
};

export default ProductOverview;
