import React from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import Section from '@components/section';
import { ImageProps } from '@components/image';
import generateId from '@helpers/generate-id';
import { Container, SlickContainer, SlickImage, Dots } from './carousel.styled';

const carouselIdGenerator = generateId();

export interface CarouselItemProps {
  image?: ImageProps;
}

interface CarouselProps {
  items?: CarouselItemProps[];
}

const Carousel = ({ items = [] }: CarouselProps): JSX.Element => {
  const settings = {
    dots: true,
    // eslint-disable-next-line react/no-unstable-nested-components
    appendDots: (dots: JSX.Element) => <Dots>{dots}</Dots>,
    speed: 0,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    swipe: true,
    swipeToSlide: true,
    focusOnSelect: true,
  };

  if (items?.length === 0 || !items[0].image?.url) {
    return <></>;
  }

  return (
    <Section backgroundColor="white">
      <Container>
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            charSet="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
        </Helmet>
        <Slider {...settings}>
          {items?.map(({ image }) => (
            <SlickContainer key={carouselIdGenerator.next().value}>
              <SlickImage url={image?.url} alt={image?.alt} />
            </SlickContainer>
          ))}
        </Slider>
      </Container>
    </Section>
  );
};

export default Carousel;
