import styled, { css, keyframes } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';

const expand = keyframes`
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
`;

export const expandAnimation = css`
  animation: 12s linear 0s 1 both ${expand};
`;

export const Container = styled.div`
  width: 100vw;
  .slick-track {
    display: flex;
    .slick-slide {
      height: auto;
      div,
      img {
        height: 100%;
      }
    }
  }
  .slick-list {
    overflow: visible;
  }
`;

export const StyledInput = styled.input`
  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.lg;

    return css`
      flex-grow: 1;
      font-weight: 500;

      ::placeholder {
        font-weight: 400;
      }

      ${generateScales('font-size', mobile.fontSize, desktop.fontSize)}
      ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('height', mobile.height, desktop.height)}
      border: 1px solid ${theme.colors.jungleGreen};
      background-color: ${theme.colors.white};
      color: ${theme.colors.bottleGreen};
      outline: none;
      font-family: ${theme.fontFamily.display};
      transition: all ${theme.transitions.normal};

      ::placeholder {
        color: ${theme.colors.bottleGreen};
      }

      :focus {
        border-color: ${theme.colors.bottleGreen};
      }
    `;
  }}
`;

export const Prompt = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.display};
    font-weight: 500;
    font-size: ${theme.fontSize.body.desktop};
    line-height: ${theme.lineHeights.sm};
  `}
`;

export const SlickContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const SliderNav = styled.nav``;

export const SliderNavList = styled.ul``;

export const Indicator = styled.div`
  ${({ theme }) => css`
    height: 0.75rem;
    width: 0.75rem;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    background-color: ${theme.colors.hintOfGreen};
  `}
`;

export const SlideSelect = styled.div<{ $isActive: boolean; $isAutoPlaying: boolean }>`
  ${({ theme, $isActive, $isAutoPlaying }) => css`
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    padding: 2rem 1rem;
    cursor: pointer;
    border-top: 1px solid ${theme.colors.hintOfGreen};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.large.desktop)}
    font-weight: ${theme.fontWeights.normal};

    &::after {
      content: '';
      display: block;
      background-color: ${theme.colors.hintOfGreen};
      height: 3px;
      border-radius: 1.5px;
      position: absolute;
      top: -2px;
      left: 0;
      right: 0;
      transform: scaleX(0);
      transform-origin: 0 0;
    }

    &:hover {
      background-color: ${theme.colors.hintOfGreen};

      ${Indicator} {
        background-color: ${theme.colors.emerald};
        opacity: 0.25;

        @media only screen and (max-width: ${theme.breakpoints.md}) {
          opacity: 1;
        }
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      padding: 1.25rem 0;
    }

    ${$isActive &&
    css`
      &::before {
        content: '';
        display: block;
        background-color: ${theme.colors.hintOfGreen};
        height: 3px;
        border-radius: 1.5px;
        position: absolute;
        top: -2px;
        left: 0;
        right: 0;
        transform-origin: 0 0;
      }

      &::after {
        ${$isAutoPlaying && expandAnimation}
        background-color: ${theme.colors.emerald};
      }

      &:hover {
        ::before {
          background-color: ${theme.colors.emerald};
          opacity: 0.25;
        }

        ${Indicator} {
          opacity: 1;
        }
      }

      ${Indicator} {
        background-color: ${theme.colors.emerald};
      }

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        &::before {
          background-color: ${theme.colors.emerald};
        }
      }
    `}
  `}
`;

export const PropertyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 1.5rem;
  align-items: center;
  margin-bottom: 1rem;
`;

export const SliderNavItem = styled.li`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.display};

    &:last-of-type ${SlideSelect} {
      border-bottom: 1px solid ${theme.colors.hintOfGreen};
    }
  `}
`;

export const SlideLabel = styled.div`
  ${({ theme }) => css`
    width: 100%;
    line-height: ${theme.lineHeights.lg};
  `}
`;

export const CodeWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    column-gap: 4rem;
    ${generateScales('margin-top', '2rem', '3rem')}

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      row-gap: 3rem;
    }
  `}
`;

export const UppercaseCode = styled.span`
  color: #99f3f9;
`;

export const DefaultCode = styled.span`
  color: #ff8b8b;
`;

export const AccentCode = styled.span`
  color: #f64ec7;
`;

export const CodeSection = styled.div`
  ${({ theme }) => css`
    width: 40rem;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      width: 30rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: 24rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 100%;
    }
  `}
`;
