import React, { useState, SyntheticEvent, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import Input from '@components/input';
import generateId from '@helpers/generate-id';
import Dropdown from '@components/dropdown';
import {
  StyledWrapper,
  Container,
  Categories,
  Category,
  StyledForm,
  StyledButton,
} from './categories.styled';

const categoriesIdGenerator = generateId();
const visibleTagsCount = 3;

interface CategoriesBlockProps {
  className?: string;
}

interface FetchData {
  tags: string[];
}

const CategoriesBlock = ({ className = '' }: CategoriesBlockProps): JSX.Element => {
  const location = useLocation();
  const activeCategory = new URLSearchParams(location.search).get('category') || '';
  const activeSearch = new URLSearchParams(location.search).get('search') || '';

  const [search, setSearch] = useState<string>(activeSearch);
  const [tags, setTags] = useState<string[]>(['']);

  useEffect(() => {
    Promise.resolve(fetch('https://coactive.cdn.prismic.io/api/v2'))
      .then((response) => response.json())
      .then((data: FetchData) => {
        setTags(data.tags);
      })
      .catch(() => 'fetch error');
  }, []);

  const onSearchInputChange = (currentValue: string) => {
    setSearch(currentValue);
  };

  const dropDownItems = tags.slice(visibleTagsCount);

  const searchClick = (event: SyntheticEvent) => {
    event.preventDefault();
    const isAnyParam = search || activeCategory;

    return navigate(
      `/blog${isAnyParam ? '?' : ''}${activeCategory ? `category=${activeCategory}` : ''}${
        search ? `${activeCategory ? '&' : ''}search=${search}` : ''
      }`
    );
  };

  return (
    <StyledWrapper>
      <Container className={className}>
        <Categories>
          <Category
            href="/blog"
            $isActive={location.pathname === '/blog' && activeCategory === '' && search === ''}
            key={categoriesIdGenerator.next().value}
          >
            All
          </Category>
          {tags.slice(0, visibleTagsCount).map((tag: string, i: number) => {
            const to = `/blog?category=${tag}`;

            return (
              i < 4 && (
                <Category
                  href={to}
                  $isActive={location.pathname === '/blog' && decodeURI(activeCategory) === tag}
                  key={categoriesIdGenerator.next().value}
                >
                  {tag}
                </Category>
              )
            );
          })}
          {!!dropDownItems.length && <Dropdown title="More" items={dropDownItems} type="blog" />}
        </Categories>
        <StyledForm onSubmit={searchClick}>
          <Input
            type="text"
            size="small"
            placeholder="Search blog"
            onChange={onSearchInputChange}
            value={search}
          />
          <StyledButton type="submit" size="sm" role="button">
            Search
          </StyledButton>
        </StyledForm>
      </Container>
    </StyledWrapper>
  );
};

export default CategoriesBlock;
