import styled from 'styled-components';
import { Link } from 'gatsby';
import Text from '@components/text';
import Section from '@components/section';
import { generateScales } from '@helpers/generate-media-queries';

export const PostsGrid = styled.div`
  ${({ theme }) => `
    justify-content: center;
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(3, 1fr);

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      grid-gap: 2rem;
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      grid-gap: 2rem;
      grid-template-columns: repeat(1, 1fr);
    }
  `}
`;

export const EmptyState = styled.p`
  ${({ theme }) => `
    color: ${theme.colors.bottleGreen};
    text-align: center;
    width: 100%;
    ${generateScales('padding-top', '2em', '4rem')}
    ${generateScales('padding-bottom', '2rem', '4rem')}
    ${generateScales(
      'font-size',
      theme.fontSize.subheading.mobile,
      theme.fontSize.subheading.desktop
    )}
  `}
`;

export const StyledSection = styled(Section)`
  ${generateScales('padding-top', '3em', '5rem')}
  ${generateScales('padding-bottom', '3rem', '5rem')}
`;

export const StyledLink = styled.a`
  width: 100%;
  text-decoration: none;
  display: inline-block;
  color: ${({ theme }) => theme.colors.jungleGreen};
`;

export const StyledGatsbyLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  display: inline-block;
  color: ${({ theme }) => theme.colors.jungleGreen};
`;

export const StyledCustomLink = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.bottleGreen};
  }
`;

export const StyledTitle = styled(Text)`
  text-align: center;
  ${generateScales('margin-bottom', '2rem', '3.5rem')}
`;

export const ImageContainer = styled.div`
  justify-content: stretch;
  align-items: stretch;
  height: 16rem;
  overflow: hidden;
  width: 100%;

  div,
  img {
    transition: transform 0.25s ease;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover div,
  &:hover img {
    transform: scale(1.125);
  }
`;

export const Meta = styled.p`
  ${({ theme }) => `
    margin: 1rem 0 1.25rem;
    color: ${theme.colors.jungleGreen};
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop)}
    font-weight: ${theme.fontWeights.normal};
    line-height: ${theme.lineHeights.base};
    text-transform: uppercase;
  `}
`;

export const Category = styled.span<{ color?: string }>`
  ${({ theme, color = 'tertiary' }) => `
    color: ${color};
    font-weight: ${theme.fontWeights.normal};
    ${generateScales(
      'font-size',
      theme.fontSize.extraSmall.mobile,
      theme.fontSize.extraSmall.desktop
    )}
    line-height: ${theme.lineHeights.base};
    text-transform: uppercase;
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => `
    margin: 0.5rem 0 0;
    color: ${theme.colors.bottleGreen};
    font-family: ${theme.fontFamily.display};
    ${generateScales('font-size', '1.125rem', '1.25rem')}
    font-weight: 400;
    line-height: ${theme.lineHeights.base};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => `
    margin: 0.5rem 0 0 0;
    color: ${theme.colors.bottleGreen};
    font-family: ${theme.fontFamily.display};
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop)}
    font-weight: 400;
    line-height: ${theme.lineHeights.base};

    + ${Meta} {
      text-transform: none;
    }
  `}
`;

export const NoImage = styled.div`
  ${({ theme }) => `
    width: 100%;
    height: 100%;
    min-height: 12rem;
    background-color: ${theme.colors.bottleGreen};
  `};
`;
