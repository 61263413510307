import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales } from '@helpers/generate-media-queries';

export const ChromeFrame = styled.div`
  ${({ theme }) => css`
    background-color: #08162d;
    border-radius: ${theme.borderRadius.small};
    overflow: hidden;
    height: 100%;
  `}
`;

export const ChromeControls = styled.div`
  display: flex;
  padding: 1.25rem 1rem;
  width: 100%;
  border-bottom: 1px solid #248499;
`;

export const ControlButton = styled.div`
  ${({ theme }) => css`
    border-radius: 0.375rem;
    height: 0.75rem;
    width: 0.75rem;
    margin-right: 0.5rem;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      height: 0.675rem;
      width: 0.675rem;
    }
  `}
`;

export const CloseControl = styled(ControlButton)`
  background-color: #13294e;
`;

export const MinimizeControl = styled(ControlButton)`
  background-color: #13294e;
`;

export const MaximizeControl = styled(ControlButton)`
  background-color: #13294e;
`;

export const ChromeBody = styled.div`
  display: flex;
  position: relative;
`;

export const LinesWrapper = styled.div`
  ${({ theme }) => css`
    text-align: right;
    padding: 0.75rem 1rem;
    border-right: 1px solid #248499;
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 0.75rem 0.75rem;
    }
  `}
`;

export const Line = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.mono};
    color: #248499;
    line-height: ${theme.lineHeights.xl};

    ${generateScales('font-size', theme.fontSize.extraSmall.mobile, theme.fontSize.small.desktop)}
  `}
`;

export const Code = styled.div`
  ${({ theme }) => css`
    text-align: left;
    padding: 0.75rem 1rem;
    font-weight: 400;
    font-family: ${theme.fontFamily.mono};
    color: ${theme.colors.white};
    line-height: ${theme.lineHeights.xl};

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      word-break: break-all;
      padding: 0.75rem 0.75rem;
    }

    ${generateScales('font-size', theme.fontSize.extraSmall.mobile, theme.fontSize.small.desktop)}
  `}
`;

export const Syntax = styled.span<{ textColor: keyof typeof defaultTheme.colors }>`
  ${({ theme, textColor }) => css`
    color: ${theme.colors[textColor]};
  `}
`;
