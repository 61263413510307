import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { ImageProps } from '@components/image';
import Wrapper from '@components/wrapper.styled';
import generateId from '@helpers/generate-id';
import {
  Container,
  Content,
  Title,
  Description,
  WrapContainer,
  Block,
  StyledLink,
  StyledImage,
  StyledLabel,
} from './resources.styled';

const resourcesIdGenerator = generateId();

export interface ResourcesItemProps {
  image?: ImageProps;
  title?: {
    text?: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  link?: {
    url: string;
  };
  link_label?: string;
}

interface ResourcesProps {
  moduleTitle?: string;
  description?: RichTextBlock[];
  items?: ResourcesItemProps[];
}

const Resources = ({ moduleTitle, description = [], items = [] }: ResourcesProps): JSX.Element => {
  const hasDescription: boolean = description?.length > 0 && description[0].text !== '';
  const hasItems: boolean =
    items?.length > 0 &&
    (items[0].image?.gatsbyImageData !== null || items[0].image?.url !== null) &&
    items[0].link?.url !== null;

  if (!moduleTitle || !hasItems) {
    return <></>;
  }

  return (
    <Container backgroundColor="dark" isDiagonal>
      <Wrapper widthSize="md">
        <Content>
          {moduleTitle && (
            <Title
              tag="h2"
              textColor="white"
              textSize="heading4"
              type="title"
              stringText={moduleTitle}
            />
          )}
          {hasDescription && <Description textColor="white" richText={description} />}
          {hasItems && (
            <WrapContainer>
              {items.map(({ image, link, link_label }) => (
                <Block key={resourcesIdGenerator.next().value}>
                  <StyledLink href={link?.url} target="_blank">
                    <StyledImage
                      gatsbyImageData={image?.gatsbyImageData}
                      url={image?.url}
                      alt={image?.alt}
                    />
                    {link_label && <StyledLabel>{link_label}</StyledLabel>}
                  </StyledLink>
                </Block>
              ))}
            </WrapContainer>
          )}
        </Content>
      </Wrapper>
    </Container>
  );
};

export default Resources;
