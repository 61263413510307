import styled, { css } from 'styled-components';
import Text from '@components/text';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Eyebrow = styled(Text)`
  margin-bottom: 1.5rem;
`;

export const WrapContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 3.5rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, auto);
    justify-content: space-between;
    grid-column-gap: 10rem;
    grid-row-gap: 3rem;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      margin-top: 4rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      grid-column-gap: 5rem;
      grid-row-gap: 3rem;
      margin-top: 6rem;
      grid-template-columns: repeat(4, auto);
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      grid-column-gap: 5rem;
      grid-row-gap: 3rem;
      margin-top: 4rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: repeat(2, auto);
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      grid-column-gap: 4rem;
      grid-row-gap: 4rem;
      grid-template-columns: repeat(2, auto);
    }

    @media only screen and (max-width: ${theme.breakpoints.xs}) {
      justify-content: center;
      grid-gap: 1rem;
      grid-row-gap: 2.5rem;
      grid-template-columns: repeat(1, 75%);
    }
  `}
`;

export const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
