import React from 'react';
import Image from '@components/image';
import generateId from '@helpers/generate-id';
import { Category, NoImage } from '@components/posts-list/posts-list.shared.styled';
import Wrapper from '@components/wrapper.styled';
import { PostPreviewType } from '@components/posts-list';
import {
  StyledLink,
  ImageContainer,
  Description,
  Container,
  DescriptionContainer,
  Title,
  DescriptionCategory,
} from './feature-post.styled';

const featurePostIdGenerator = generateId();
const categoryPostIdGenerator = generateId();

interface FeaturePostProps {
  posts: PostPreviewType[];
}

const FeaturePost = ({ posts }: FeaturePostProps): JSX.Element => (
  <>
    {posts.map(({ uid, data, tags }: PostPreviewType) => (
      <Wrapper key={featurePostIdGenerator.next().value}>
        <Container>
          <StyledLink href={`/blog/${uid}`}>
            <ImageContainer>
              {data.post_image?.url || data.post_image?.gatsbyImageData ? (
                <Image
                  url={data.post_image.url}
                  alt={data.post_image.alt}
                  gatsbyImageData={data.post_image.gatsbyImageData}
                />
              ) : (
                <NoImage />
              )}
            </ImageContainer>
          </StyledLink>
          <DescriptionContainer>
            <DescriptionCategory>
              {tags.map((tag: string) => (
                <Category key={categoryPostIdGenerator.next().value}>{tag}&nbsp;</Category>
              ))}
            </DescriptionCategory>
            {data.post_title && data.post_title[0] && (
              <Title type="title" tag="h3" stringText={data.post_title[0].text} />
            )}
            {data.post_title && data.post_title[0] && (
              <Description>{data.post_title[0].text}</Description>
            )}
          </DescriptionContainer>
        </Container>
      </Wrapper>
    ))}
  </>
);

export default FeaturePost;
