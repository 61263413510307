import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '@layouts/default-layout';
import SEO from '@components/seo';
import renderModule from '@components/module';
import generateId from '@helpers/generate-id';
import { ImageProps } from '@components/image';

const indexPageIdGenerator = generateId();

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface MainPageInterface {
  prismicGeneralConfig: {
    data: {
      page_name?: string;
      favicon?: {
        url?: string;
      };
      logo?: ImageProps;
    };
  };
  allPrismicPage: {
    nodes: {
      data: {
        page_title?: {
          text: string;
        };
        meta_description?: string;
        meta_title?: string;
        meta_image?: {
          url?: string;
        };
        body: [];
      };
    }[];
  };
}

const IndexPage: React.FC = () => {
  const {
    allPrismicPage,
    prismicGeneralConfig: {
      data: { favicon },
    },
  }: MainPageInterface = useStaticQuery(
    graphql`
      query MainPageQuery {
        prismicGeneralConfig {
          ...GeneralConfigFragment
        }
        allPrismicPage(filter: { uid: { eq: "home" } }) {
          nodes {
            ...PageFragment
          }
        }
      }
    `
  );

  return (
    <Layout>
      <SEO
        meta_image={allPrismicPage?.nodes[0].data.meta_image?.url}
        meta_description={allPrismicPage?.nodes[0].data.meta_description}
        meta_title={allPrismicPage?.nodes[0].data.meta_title}
        title={allPrismicPage?.nodes[0].data.page_title?.text || 'Home'}
        faviconHref={favicon?.url || ''}
      />
      <Container>
        {allPrismicPage.nodes[0].data.body.map(
          (module) =>
            module && (
              <React.Fragment key={indexPageIdGenerator.next().value}>
                {renderModule(module)}
              </React.Fragment>
            )
        )}
      </Container>
    </Layout>
  );
};

export default IndexPage;
