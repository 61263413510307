import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import Text, { TextHead } from '@components/text';
import Wrapper from '@components/wrapper.styled';
import Section from '@components/section';
import { ImageProps } from '@components/image';
import { defaultTheme } from '@styles/theme.styled';
import generateId from '@helpers/generate-id';
import getContrastYIQ from '@helpers/get-color-contrast';
import Arrow from '@components/svg-icons/arrow';
import {
  GridBlock,
  FlexContainer,
  StyledGrid,
  StyledBlock,
  StyledTitle,
  StyledHeading,
  StyledSubheading,
  StyledDescription,
  StyledExternalLink,
  LeftStyledBlock,
} from './feature-grid.styled';

const featureGridIdGenerator = generateId();

export interface FeatureGridItemType {
  title?: {
    text: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
}

interface FeatureGridProps {
  eyebrow?: string;
  title?: string;
  subheading?: RichTextBlock[];
  link?: {
    url: string;
    target: string;
  };
  linkLabel?: string;
  backgroundColor?: keyof typeof defaultTheme.colors;
  backgroundImage?: ImageProps;
  backgroundPosition?: string;
  itemsPerRow?: string;
  items?: FeatureGridItemType[];
}

const FeatureGrid = ({
  eyebrow,
  title,
  subheading,
  link,
  linkLabel = '',
  backgroundColor = 'white',
  backgroundImage,
  backgroundPosition = 'left',
  itemsPerRow = '2',
  items = [],
}: FeatureGridProps) => {
  const textColor = getContrastYIQ(defaultTheme.colors[backgroundColor]);
  const layoutType = ((): 'row' | 'column' => (+itemsPerRow === 2 ? 'row' : 'column'))();

  const renderHeader = (): JSX.Element => (
    <>
      {!!eyebrow && (
        <Text type="eyebrow" stringText={eyebrow} textSize="eyebrow" textColor={textColor} />
      )}
      {!!title && (
        <StyledHeading
          type="title"
          tag="h2"
          textSize="heading2"
          stringText={title}
          textColor={textColor}
        />
      )}
      {!!subheading?.length && (
        <StyledSubheading type="subheading" richText={subheading} textColor={textColor} />
      )}
      {Boolean(link?.url) && (
        <StyledExternalLink href={link?.url} target={link?.target}>
          {linkLabel}
          <Arrow />
        </StyledExternalLink>
      )}
    </>
  );

  return (
    <Section
      backgroundColor={backgroundColor}
      backgroundImageUrl={backgroundImage?.url}
      backgroundPosition={backgroundPosition}
    >
      <Wrapper>
        <FlexContainer layout={layoutType}>
          {layoutType === 'column' ? (
            <TextHead align="left">{renderHeader()}</TextHead>
          ) : (
            <LeftStyledBlock>{renderHeader()}</LeftStyledBlock>
          )}
          <GridBlock>
            <StyledGrid itemsPerRow={+itemsPerRow}>
              {items?.map((item) => (
                <StyledBlock key={featureGridIdGenerator.next().value} textColor={textColor}>
                  {!!item.title?.text && (
                    <StyledTitle
                      type="title"
                      stringText={item.title.text}
                      textSize="heading6"
                      textColor={textColor}
                    />
                  )}
                  {!!item.description?.richText && (
                    <StyledDescription
                      type="description"
                      richText={item.description.richText}
                      textSize="body"
                      textColor={textColor}
                    />
                  )}
                </StyledBlock>
              ))}
            </StyledGrid>
          </GridBlock>
        </FlexContainer>
      </Wrapper>
    </Section>
  );
};

export default FeatureGrid;
