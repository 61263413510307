import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { headerHeight } from '@components/header/header.styled';
import BGImage from '@images/hero-background.svg';
import HeroMask from '@images/hero-mask.svg';
import HeroTabletMask from '@images/hero-tablet-mask.svg';
import HeroMobileMask from '@images/hero-mobile-mask.svg';
import Text from '@components/text';
import Image from '@components/image';
import Wrapper from '@components/wrapper.styled';
import { generateScales } from '@helpers/generate-media-queries';

export const StyledSubheading = styled(Text)`
  display: block;
  ${generateScales('margin-top', '1rem', '1.5rem')}
`;

export const StyledDescription = styled(Text)`
  display: block;
  ${generateScales('margin-top', '1rem', '1.5rem')}
`;

interface WrapperProps {
  hasImage: boolean;
}

export const StyledWrapper = styled(Wrapper)<WrapperProps>`
  z-index: 3;
  position: relative;
`;

interface HeaderProps {
  backgroundColor: keyof typeof defaultTheme.colors;
  isInline: boolean;
  hasImage: boolean;
}

export const Header = styled.header<HeaderProps>`
  ${({ isInline, hasImage }) =>
    isInline &&
    hasImage &&
    css`
      background-image: url(${BGImage});
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: bottom center;
    `}

  width: 100%;
  position: relative;
  ${generateScales('padding-top', '2rem', '8rem')}
  ${generateScales('padding-bottom', '2rem', '8rem')}

  &::before {
    content: '';
    display: block;
    height: ${headerHeight};
    width: 1px;
  }

  ${({ theme, backgroundColor, isInline, hasImage }) => css`
    background-color: ${theme.colors[backgroundColor]};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      ${hasImage &&
      !isInline &&
      css`
        padding-bottom: 20rem;
      `}

      &::before {
        height: 5rem;
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      ${hasImage &&
      !isInline &&
      css`
        padding-bottom: 14rem;
      `}
    }
  `}
`;

export const ImageContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: calc(50%);
    left: calc(50% - (${theme.wrappers.xxl} / 2) + 50% + (${theme.spacing.wrapperGap} * 4));
    transform: translateY(-50%);
    width: calc(50% - ${theme.spacing.wrapperGap});
    height: 100%;
    min-height: 24rem;
    justify-content: stretch;
    align-items: stretch;
    overflow: hidden;

    div {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      left: calc(50% - (${theme.wrappers.xl} / 2) + 50% + (${theme.spacing.wrapperGap} * 4));
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      left: calc(50% - (${theme.wrappers.lg} / 2) + 50% + (${theme.spacing.wrapperGap} * 4));
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
      width: 100%;
      height: auto;
      margin: 0 auto;
      margin-top: 3rem;
      justify-content: center;
    }
  `}
`;

interface TextContainerProps {
  hasImage: boolean;
  isInline: boolean;
}

export const TextContainer = styled.div<TextContainerProps>`
  ${({ theme, hasImage, isInline }) => css`
    margin: ${hasImage ? 'unset' : '0 auto'};
    text-align: ${hasImage ? 'left' : 'center'};
    max-width: 50%;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      max-width: 50%;
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      max-width: 50%;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: ${isInline ? '100%' : '75%'};
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-width: 100%;
      + ${ImageContainer} {
        margin-top: 2rem;
      }
    }
  `}
`;

export const BackgroundImage = styled(Image)`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 72rem;

  div {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

export const FloatingBackgroundImage = styled.img`
  position: absolute;
  z-index: 1;
  top: 0;
  height: auto;

  ${({ theme }) => css`
    left: calc(50% - (${theme.wrappers.xxl} / 2) + ${theme.spacing.wrapperGap});
    width: calc(${theme.wrappers.xxl} * 2);

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      left: calc(50% - (${theme.wrappers.xl} / 2) + ${theme.spacing.wrapperGap});
      width: calc(${theme.wrappers.xl} * 2);
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      left: calc(50% - (${theme.wrappers.lg} / 2) + ${theme.spacing.wrapperGap});
      width: calc(${theme.wrappers.lg} * 2);
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: none;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      display: none;
    }
  `}
`;

export const FloatingMobileBackgroundImage = styled.img`
  ${({ theme }) => css`
    position: absolute;
    z-index: 1;
    top: calc(100% - 14rem);
    height: auto;
    width: 100%;
    left: 0;
    display: none;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      display: unset;
    }
  `}
`;

export const FloatingTabletBackgroundImage = styled.img`
  ${({ theme }) => css`
    position: absolute;
    z-index: 1;
    top: calc(100% - 24rem);
    height: auto;
    width: 200%;
    left: 0;
    display: none;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: unset;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      top: calc(100% - 20rem);
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      display: none;
    }
  `}
`;

export const FloatingMask = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  mask-image: url(${HeroMask});
  mask-size: 100% 100%;
  mask-repeat: no-repeat;

  ${({ theme }) => css`
    left: calc(50% - (${theme.wrappers.xxl} / 2) + ${theme.spacing.wrapperGap});

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      left: calc(50% - (${theme.wrappers.xl} / 2) + ${theme.spacing.wrapperGap});
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      left: calc(50% - (${theme.wrappers.lg} / 2) + ${theme.spacing.wrapperGap});
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      top: calc(100% - 24rem);
      left: 0;
      mask-image: url(${HeroTabletMask});
      width: 200%;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      top: calc(100% - 20rem);
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      top: calc(100% - 14rem);
      left: 0;
      mask-image: url(${HeroMobileMask});
      width: 100%;
    }
  `}
`;

export const MaskedBackgroundImage = styled.img`
  position: absolute;
  top: 0;
  height: 50%;
  width: auto;

  ${({ theme }) => css`
    left: calc(${theme.wrappers.xxl});
    transform: translateX(-100%);

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      left: calc(${theme.wrappers.xl});
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      left: calc(${theme.wrappers.lg});
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      top: 15%;
      left: 50%;
      width: auto;
      height: 90%;
      transform: translateY(-50%) translateX(-100%);
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      top: 10%;
      left: 105%;
      width: auto;
      height: 85%;
      transform: translateY(-50%) translateX(-100%);
    }
  `}
`;

export const PlacerMobileMask = styled.img`
  display: none;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      height: auto;
      width: 100%;
      opacity: 0;
      display: unset;
    }
  `}
`;

export const PlacerTabletMask = styled.img`
  display: none;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      height: auto;
      width: 100%;
      opacity: 0;
      display: unset;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      height: auto;
      width: 100%;
      opacity: 0;
      display: none;
    }
  `}
`;

export const PlacerMask = styled.img`
  ${({ theme }) => css`
    width: calc(${theme.wrappers.xxl} * 2);
    height: auto;
    opacity: 0;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      width: calc(${theme.wrappers.xl} * 2);
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      width: calc(${theme.wrappers.lg} * 2);
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: none;
    }
  `}
`;
