import React from 'react';
import Image, { ImageProps } from '@components/image';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import Text, { TextHead } from '@components/text';
import { defaultTheme } from '@styles/theme.styled';
import generateId from '@helpers/generate-id';
import getContrastYIQ from '@helpers/get-color-contrast';
import { Container, Eyebrow, WrapContainer, Block, StyledLink } from './investors.styled';

const investorsIdGenerator = generateId();
const textColor = getContrastYIQ(defaultTheme.colors.bottleGreen);

export interface InvestorsPropsItem {
  image?: ImageProps;
  link?: {
    url: string;
  };
}

export interface InvestorsProps {
  eyebrow?: string;
  title?: string;
  items?: InvestorsPropsItem[];
}

const Investors = ({ eyebrow, title, items = [] }: InvestorsProps): JSX.Element => {
  const areItemsProvided: boolean =
    items?.length > 0 &&
    (items[0].image?.gatsbyImageData !== null || items[0].image?.url !== null) &&
    items[0].link?.url !== null;

  if (!title || !areItemsProvided) {
    return <></>;
  }

  return (
    <Section backgroundColor="bottleGreen">
      <Wrapper alignment="center">
        <Container>
          <TextHead>
            <Eyebrow type="eyebrow" stringText={eyebrow} />
            <Text
              tag="h3"
              textColor={textColor}
              textSize="heading3"
              type="title"
              stringText={title}
            />
          </TextHead>
          {areItemsProvided && (
            <WrapContainer>
              {items?.map(({ image, link }) => {
                if (image?.url === null && link?.url === '') {
                  return null;
                }

                return (
                  <StyledLink
                    href={link?.url}
                    rel="noreferrer"
                    target="_blank"
                    key={investorsIdGenerator.next().value}
                  >
                    <Block>
                      <Image
                        gatsbyImageData={image?.gatsbyImageData}
                        url={image?.url}
                        alt={image?.alt}
                      />
                    </Block>
                  </StyledLink>
                );
              })}
            </WrapContainer>
          )}
        </Container>
      </Wrapper>
    </Section>
  );
};

export default Investors;
