import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import Image, { ImageProps } from '@components/image';
import { defaultTheme } from '@styles/theme.styled';
import BGImage from '@images/hero-mask-background.svg';
import MobileBGImage from '@images/hero-mask-mobile-background.svg';
import TabletBGImage from '@images/hero-mask-tablet-background.svg';
import HeroMask from '@images/hero-mask.svg';
import Text from '@components/text';
import {
  StyledWrapper,
  StyledSubheading,
  StyledDescription,
  Header,
  TextContainer,
  ImageContainer,
  FloatingBackgroundImage,
  FloatingMobileBackgroundImage,
  FloatingTabletBackgroundImage,
  FloatingMask,
  PlacerMask,
  MaskedBackgroundImage,
  PlacerMobileMask,
  PlacerTabletMask,
} from './page-hero.styled';

export interface PageHeroProps {
  title?: string;
  description?: RichTextBlock[];
  subheading?: RichTextBlock[];
  image?: ImageProps;
  imageLayout: 'inline' | 'masked';
  backgroundColor?: keyof typeof defaultTheme.colors;
}

const PageHero = ({
  title,
  subheading = [],
  description = [],
  image,
  imageLayout = 'inline',
  backgroundColor = 'white',
}: PageHeroProps): JSX.Element => {
  const isSubheadingProvided = subheading?.length > 0 && subheading[0].text;
  const isDescriptionProvided = description?.length > 0 && description[0].text;
  const isImageProvided = !!(image?.gatsbyImageData || image?.url);
  const isInlineImage = imageLayout === 'inline';

  if (!title && !isSubheadingProvided) {
    return <></>;
  }

  return (
    <Header
      className="page-hero"
      backgroundColor={backgroundColor}
      isInline={isInlineImage}
      hasImage={isImageProvided}
    >
      <StyledWrapper hasImage={isImageProvided}>
        <TextContainer hasImage={isImageProvided} isInline={isInlineImage}>
          {title && <Text tag="h1" textSize="heading1" type="title" stringText={title} />}
          {isSubheadingProvided && <StyledSubheading type="subheading" richText={subheading} />}
          {isDescriptionProvided && <StyledDescription textSize="body" richText={description} />}
        </TextContainer>
        {isImageProvided && isInlineImage && (
          <ImageContainer>
            <Image gatsbyImageData={image?.gatsbyImageData} url={image?.url} alt="" />
          </ImageContainer>
        )}
      </StyledWrapper>
      {!isInlineImage && (
        <>
          <FloatingBackgroundImage src={BGImage} alt="" />
          <FloatingMobileBackgroundImage src={MobileBGImage} alt="" />
          <FloatingTabletBackgroundImage src={TabletBGImage} alt="" />
          <FloatingMask>
            <MaskedBackgroundImage src={image?.url} alt="" />
            <PlacerMask src={HeroMask} alt="" />
            <PlacerMobileMask src={MobileBGImage} alt="" />
            <PlacerTabletMask src={TabletBGImage} alt="" />
          </FloatingMask>
        </>
      )}
    </Header>
  );
};

export default PageHero;
