import styled, { css } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import CircleMask from '@images/bio_mask_circle.svg';
import HexagonMask from '@images/bio_mask_hexagon.svg';
import HexagonRightMask from '@images/bio_mask_half-hexagon-right.svg';
import HexagonLeftMask from '@images/bio_mask_half_hexagon-left.svg';
import HalfCircleMask from '@images/bio_mask_half-circle.svg';

export const SocialLinkContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    column-gap: 0.5rem;
    ${generateScales('margin-top', '1rem', '2rem')}

    @media (max-width: ${theme.breakpoints.md}) {
      justify-content: center;
    }
  `}
`;

export const SocialLink = styled.a`
  ${({ theme }) => css`
    display: inline-flex;
    height: 2.5rem;
    border-radius: 1.25rem;
    background-color: ${theme.colors.hintOfGreen};
    padding: 0 1rem;
    align-items: center;
  `}
`;

export const StyledImage = styled.img`
  height: 1.25rem;
  width: auto;
`;

export const Subtitle = styled.h4`
  ${({ theme }) => `
    font-family: ${theme.fontFamily.display};
    font-weight: ${theme.fontWeights.normal};
    line-height: ${theme.lineHeights.sm};
    color: ${theme.colors.emerald};
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop)}
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => `
    margin: 0;
    font-family: ${theme.fontFamily.display};
    ${generateScales('font-size', theme.fontSize.large.mobile, theme.fontSize.large.desktop)}
    font-weight: 500;
    line-height: ${theme.lineHeights.sm};

    + ${Subtitle} {
      margin-top: 0.75rem;
    }
  `}
`;

export const Description = styled.span`
  display: block;
  margin: 1rem 0 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
`;

export const Meta = styled.div`
  ${({ theme }) => css`
    ${generateScales('margin-top', '1rem', '2rem')}
    text-align: center;
    font-family: ${theme.fontFamily.display};
  `}
`;

export const ImageContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: ${({ isOpen }) => (isOpen ? '50%' : '16rem')};
  mask-image: url(${CircleMask});
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
  height: 18rem;
  margin: 0 auto;

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.xl}) {
      height: 14rem;
      width: 100%;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      height: 11rem;
      width: 11rem;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      height: 9rem;
      width: 100%;
      width: min(100%, 9rem);
    }

    @media (max-width: ${theme.breakpoints.xs}) {
      height: 14rem;
      width: 100%;
    }
  `}

  div,
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
  }

  &:hover div,
  &:hover img {
    transform: ${({ isOpen }) => (isOpen ? 'unset' : 'scale(1.025)')};
  }
`;

export const Card = styled.div<{ isOpen: boolean; itemsPerRow?: number }>`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.text};
    border-radius: ${theme.spacing.sm};
    background-color: ${theme.colors.white};
    ${generateScales('margin', '0.25rem', '1rem')}
    overflow: hidden;
    cursor: pointer;
  `}

  &:nth-child(1) ${ImageContainer} {
    mask-image: url(${HexagonMask});
  }

  &:nth-child(2) ${ImageContainer} {
    mask-image: url(${HalfCircleMask});
  }

  &:nth-child(3) ${ImageContainer} {
    mask-image: url(${HexagonRightMask});
  }

  &:nth-child(4) ${ImageContainer} {
    mask-image: url(${HexagonLeftMask});
  }

  &:nth-child(5) ${ImageContainer} {
    mask-image: url(${CircleMask});
  }

  &:nth-child(6) ${ImageContainer} {
    mask-image: url(${HexagonMask});
  }

  &:nth-child(7) ${ImageContainer} {
    mask-image: url(${HalfCircleMask});
  }

  &:nth-child(8) ${ImageContainer} {
    mask-image: url(${HexagonRightMask});
  }

  &:nth-child(9) ${ImageContainer} {
    mask-image: url(${HexagonLeftMask});
  }

  ${({ theme, isOpen }) =>
    isOpen &&
    css`
      grid-column: 1 / 4;
      grid-row: 1 / 4;
      display: flex;
      column-gap: 4rem;
      align-items: center;

      ${ImageContainer} {
        width: 24rem;
        height: 20rem;
      }

      ${Meta} {
        width: 50%;
        text-align: left;
        margin-top: 0;

        ${Title} {
          ${generateScales(
            'font-size',
            theme.fontSize.heading4.mobile,
            theme.fontSize.heading4.desktop
          )}
          color: ${theme.colors.jungleGreen};
        }

        ${Subtitle} {
          color: ${theme.colors.bottleGreen};
          ${generateScales(
            'font-size',
            theme.fontSize.subheading.mobile,
            theme.fontSize.subheading.desktop
          )}
        }

        ${Description} {
          ${generateScales('margin-top', '1rem', '1.5rem')}
          ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop)}
          line-height: ${theme.lineHeights.lg};
        }
      }

      @media (max-width: ${theme.breakpoints.md}) {
        flex-direction: column;
        row-gap: 1rem;
        grid-column: 1 / 3;

        ${Title}, ${Subtitle}, ${Description} {
          text-align: center;
        }

        ${ImageContainer} {
          width: 16rem;
          height: 16rem;
        }

        ${Meta} {
          width: 100%;
        }
      }

      @media (max-width: ${theme.breakpoints.xs}) {
        grid-column: 1 / 2;
      }
    `}
`;
